import React from "react"
import { connect } from "react-redux"

import { useQueryParam, StringParam } from "use-query-params";

import { updateTrackingData } from "../state/app"

//?utm_source=test&utm_medium=test&utm_campaign=test&utm_term=test&utm_content=test
//?utm_source=lasa&utm_medium=website&utm_campaign=partners

const TrackReferral = ( props ) => {
    const [utm_source] = useQueryParam("utm_source", StringParam);
    const [utm_medium] = useQueryParam("utm_medium", StringParam);
    const [utm_campaign] = useQueryParam("utm_campaign", StringParam);
    const [utm_term] = useQueryParam("utm_term", StringParam);
    const [utm_content] = useQueryParam("utm_content", StringParam);

    props.dispatch(updateTrackingData({
        utm_source: utm_source ? utm_source : null,
        utm_medium: utm_medium ? utm_medium : null,
        utm_campaign: utm_campaign ? utm_campaign : null,
        utm_term: utm_term ? utm_term : null,
        utm_content: utm_content ? utm_content : null
    }))

    return (
      <></>
    );
}

export default connect(null,null)(TrackReferral)