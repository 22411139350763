// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer
    style={{
      backgroundColor: `#e8e8e8`,
    }}
  >
    <div className={`clear`}
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
        padding: `6.5em 0 3em`,
      }}
    >

        © {new Date().getFullYear()}
        {' '}
        <a href="https://governanceevaluator.com/?utm_source=ACSWebsite&utm_medium=website&utm_campaign=navigation">Governance Evaluator</a>

    </div>
  </footer>
)

export default Footer
