import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"

import "./header.css"

const Header = ({ siteTitle }) => (
  <header className={`header-container`}>
    <div className={`header-div`}>
      <div className={`header-content`}>
        <div className={`header-text`}>
          <div>
            <h1 style={{ margin: 0 }}>
              <Link
                to="/"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                Aged Care Governance Assurance Program
              </Link>
            </h1>
          </div>
          <div>
            <h3 style={{color: `white`}}>Inclusive of Standard 8 Organisational Governance</h3>
          </div>
        </div>
        <div className={`header-logo`}>
          A solution by
          <a href="https://governanceevaluator.com/?utm_source=ACSWebsite&utm_medium=website&utm_campaign=navigation"><Image src="GE_Logo_med_trans.png" alt="Governance Evaluator" className={"header-logoimage"} /></a>
        </div>
        <div className={'clear'}></div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header